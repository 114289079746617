import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'defaultString'
})
export class DefaultStringPipe implements PipeTransform {
  transform(value: string | null, defaultStr: string = 'Default Value'): string {
    return value || defaultStr
  }
}
