import { CommonModule } from '@angular/common'
import { Component, HostBinding, Inject, inject, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { ContactFormComponent } from '../../contact-form/contact-form.component'
import { MatIconModule } from '@angular/material/icon'
import { ButtonModule } from '../../button/button.module'
import { MatButtonModule } from '@angular/material/button'
import { AssetCardMiniComponent } from '../../asset-card-mini/asset-card-mini.component'
import { NgxMaskPipe } from 'ngx-mask'
import { IContactDialogOptions } from 'src/app/core/interfaces/modal.interface'
import { ImageService } from 'src/app/core/services/image.service'
import { LegacyImageProcessorTypes } from 'src/app/core/interfaces/image.interface'
import { GlobalDataService } from 'src/app/core/services/global-data.service'


declare global {
  interface Window {
    gtag: any
  }
}

@Component({
  selector: 'contact-dialog-component',
  template: `
    @if (data.title) {
      <div mat-dialog-title [class.submission-confirmed]="submitTrue">
        @if (!submitTrue) {
          <span data-testid="title">{{data.title}}</span>
        }
        <div class="close-button-wrapper">
          <button mat-icon-button mat-dialog-close aria-label="Close dialog" i18n-aria-label="@@contactDialog_CloseDialogButtonAriaLabel">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
    }
    <div mat-dialog-content (submitSuccess)="onSubmitSuccess()">
      @if (data.asset && !submitTrue) {
        <div class="left-side">
          <app-asset-card-mini
            data-testid="asset-card-mini"
            [asset]="data.asset"
            [defaultImage]="globalDataService.defaultImage()"
            [currencyCode]="globalDataService.selectedCurrency().code"
            [badgesConfig]="data.badgesConfig"
          ></app-asset-card-mini>
          @if (getPhone()) {
            <div class="phone overline" >
              <span i18n="@@contactDialog_CallUsLabel">Call us at:</span><br />
              <p class="body-1-medium">{{getPhone() | mask : '(AAA) AAA-AAAA||+AAAAAAAAAAAAAAA' }}</p>
            </div>
          }
        </div>
      }
      @let generalConfig = globalDataService.config().general;
      <app-contact-form
        data-testid="contact-form"
        [type]="data.asset ? 'asset' : 'contact'"
        [successTitle]="data.successTitle"
        [successBody]="data.successBody"
        [acknowledgementEnabled]="generalConfig.data.contact_form_acknowledgement_enabled ?? false"
        [customAcknowledgementText]="generalConfig.data.custom_acknowledgement_text ?? ''"
        [additionalData]="assetData"
        [hideSubmitButton]="true"
        [withCloseButton]="true"
        (submitSuccess)="onSubmitSuccess()"
        (closeForm)="dialogRef.close()"
        #contactForm
      ></app-contact-form>
    </div>
    @if (!submitTrue) {
      <div mat-dialog-actions class="desktop-actions">
        <app-button
          i18n="@@contactDialog_CancelButtonText"
          btn-large
          btn-outlined
          [mat-dialog-close]
        >Cancel</app-button>
        <app-button
          i18n="@@contactDialog_SendMessageButtonText"
          btn-large
          btn-contained
          (click)="submit()"
        >Send message</app-button>
      </div>
      <div mat-dialog-actions class="mobile-actions">
        <app-button
          i18n="@@contactDialog_SendMessageButtonText"
          btn-large
          btn-contained
          (click)="submit()"
        >Send message</app-button>
      </div>
    }
  `,
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    ContactFormComponent,
    ButtonModule,
    AssetCardMiniComponent,
    NgxMaskPipe,
  ]
})
export class ContactDialogComponent {
  window = inject(Window)
  @HostBinding('class.submission-confirmed') submitTrue: boolean = false
  @ViewChild('contactForm') contactForm!: ContactFormComponent

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IContactDialogOptions,
    public dialogRef: MatDialogRef<ContactDialogComponent>,
    protected globalDataService: GlobalDataService,
    private _imageService: ImageService,
  ) {}

  get assetData() {
    return this.data.asset ? {
      equipment_number: this.data.asset?.equipment_number,
      year: this.data.asset?.year?.toString(),
      make: this.data.asset?.make,
      model: this.data.asset?.model,
      list_price: this.data.asset?.list_price?.toString(),
      category: this.data.asset?.category ?? this.data.asset?.filter_category,
      category_id: this.data.asset?.category_id,
      sub_category: this.data.asset?.sub_category ?? this.data.asset?.filter_sub_category,
      subcategory_id: this.data.asset?.subcategory_id,
      branch_state: this.data.asset?.branch_state,
      branch_city: this.data.asset?.branch_city,
      branch_code: this.data.asset?.branch_zipcode,
      asset_photo: this.data.asset?.images[0] ?
        this._imageService.buildLegacyProcessorSrc({ guid: this.data.asset?.images[0], type: LegacyImageProcessorTypes.Normal })
        : null,
      serial_number: this.data.asset?.serial_number,
    } : {}
  }

  submit(): void {
    this.contactForm.submit()
  }

  onSubmitSuccess(): void {
    this.submitTrue = true
  }

  getPhone(): string {
    return this.data.asset?.branch_phone?.trim() ?? this.data.channelWebshopSellingContactPhone ?? ''
  }

  phoneClicked() {
    if (this.window.gtag) {
      this.window.gtag('event', 'phone_number_lead_click', {
        equipment_number: this.data.asset?.equipment_number,
        fleet_asset_id: this.data.asset?.fleet_asset_id,
      })
    }
  }
}
