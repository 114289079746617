import { CommonModule } from '@angular/common'
import { AfterViewInit, Component, Inject, inject, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { ButtonModule } from '../../button/button.module'
import { MatButtonModule } from '@angular/material/button'
import { GlobalDataService } from 'src/app/core/services/global-data.service'
import { GalleryComponent, GalleryItem, GalleryModule, ImageItem } from 'ng-gallery'
import { IGalleryDialogOptions } from 'src/app/core/interfaces/modal.interface'


declare global {
  interface Window {
    gtag: any
  }
}

@Component({
  selector: 'gallery-dialog-component',
  template: `
    @if (data.title) {
      <div mat-dialog-title class="title">
        <span data-testid="title">{{data.title}}</span>
        <button class="close" mat-icon-button mat-dialog-close aria-label="Close dialog" i18n-aria-label="@@contactDialog_CloseDialogButtonAriaLabel">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    }
    <div mat-dialog-content class="content" data-testid="gallery-dialog">
      <gallery #gallery class="gallery" imageSize="contain" [counter]="false" [autoHeight]="false" [nav]="false" [loop]="true">
        <ng-container *galleryThumbDef="let item; let active = active">
          <span class="thumb" [class.active]="active">
          </span>
        </ng-container>
      </gallery>
      <button class="nav nav-left" mat-icon-button aria-label="Previous" (click)="galleryRef.prev()">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <button class="nav nav-right" mat-icon-button aria-label="Next" (click)="galleryRef.next()">
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  `,
  styles: [`
    @use "sass:map";
    @import "src/assets/_colors";
    @import "src/assets/zindex";
    @import 'src/assets/mixins';

    .title {
      display: inline-block;
      width: 100%;
      border-bottom: unset;
    }

    .close {
      float: right;
      margin-top: 10px;
    }

    .content {
      display: block;
      position: relative;
      padding-top: 0 !important;
    }

    .gallery {
      width: auto;
      height: calc(100% - 32px);
      max-height: 700px;
      background-color: white;
    }

    .thumb {
      width: 100%;
      height: 100%;

      &.active {
        border: 4px solid map-get($light, primary, main);
      }
    }

    .nav {
      position: absolute;
      bottom: 50px;
      z-index: 1;

      &-left{
        left: 10px;
      }

      &-right{
        right: 10px;
      }
    }

    ::ng-deep gallery-thumb gallery-image {
      opacity: 1 !important;
    }

    ::ng-deep gallery-thumbs {
      padding-top: 20px;
    }

  `],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    GalleryModule,
    ButtonModule,
  ]
})
export class GalleryDialogComponent implements AfterViewInit {
  window = inject(Window)
  @ViewChild('gallery') galleryRef!: GalleryComponent

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IGalleryDialogOptions,
    public dialogRef: MatDialogRef<GalleryDialogComponent>,
    protected globalDataService: GlobalDataService,
  ) {}

  ngAfterViewInit(): void {
    this.galleryRef.load(this.galleryImages())
  }

  galleryImages(): Array<GalleryItem> {
    return this.data.images.map(({ src, thumb }: { src: string, thumb: string }) => new ImageItem({ src,thumb }))
  }
}
