import { ChangeDetectorRef, Component, inject, input, OnInit } from '@angular/core'
import { EventService } from '../../../core/services/event.service'
import { LogService } from 'src/app/core/services/log.service'
import { ConfigSection } from 'src/app/core/interfaces/config.interface'
import { MediaLibraryService } from 'src/app/core/services/media-library.service'
import { get, set } from 'lodash-es'

@Component({
  template: '',
})
export abstract class DataInputComponent {
  protected eventService = inject(EventService)
  protected logger = inject(LogService)
  protected mediaLibraryService = inject(MediaLibraryService)
  protected cd = inject(ChangeDetectorRef)

  data = input<any>({})
  key = input<string>('')
  label = input<string>('')
  default = input<string>('')
  configSection = input<ConfigSection>()
  placeholder = input<string>('')
  pattern = input<string | RegExp>('.*')

  getDefaultValue(): string {
    return get(this.data(), this.key(), this.default())
  }

  updateDataValue(value: any): void {
    set(this.data(), this.key(), value)
    this.eventService.notifyConfigUpdated(this.configSection())
  }
}
